import React from 'react';
import PropTypes from 'prop-types';
import style from './Carousel.module.css';
import useTransition from '../../hooks/useTransition';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function Carousel ({ children, width, unit }) {
    const {
        translate,
        items,
        setAction,
    } = useTransition(width, children);

    return (
        <div>
        <div 
            className={style.Parent}
            style={{
                width: `${width}${unit}`,
            }}
        >
            <div className={style.Container}>
                <div
                    className={style.Inner}
                    style={{
                        width: `${width * items.length}${unit}`,
                        transform: `translateX(-${translate}${unit})`,
                    }}
                >
                    {
                        items.map((item, index) => (
                            <div 
                                key={index}
                                className={style.Item}
                                style={{
                                    width: `${width}${unit}`,
                                }}
                            >
                                {item}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
        <div className={style.Controls}>
            <button
                className={style.Prev}
                onClick={() => setAction('prev')}                >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
                className={style.Next}
                onClick={() => setAction('next')}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
        </div>
    );
};

Carousel.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired,
    width: PropTypes.number,
    unit: PropTypes.string,
};
  
  Carousel.defaultProps = {
    width: 500,
    unit: 'px',
};