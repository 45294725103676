import React from 'react';
import style from './About.module.css';
import AboutCard from '../Cards/AboutCard';
import Button from '../Button/Button';
import { faWallet, faThumbsUp, faHandshake, faBusinessTime, faTools } from '@fortawesome/free-solid-svg-icons';

const About = () => {
    return (
        <div id="about" className={style.Container}>
            <div className="wrapper">
                <div className={style.CardSection}>
                    <AboutCard 
                        icon={faWallet}
                        title="No Upfront Payments"
                    />
                    <AboutCard 
                        icon={faThumbsUp}
                        title="Satisfaction Guarantee"
                    />
                    <AboutCard 
                        icon={faBusinessTime}
                        title="Emergency Service"
                    />
                    <AboutCard 
                        icon={faHandshake}
                        title="Fixed Right Promise"
                    />
                    <AboutCard 
                        icon={faTools}
                        title="10+ Years Experience"
                    />
                </div>
                <div className={style.AboutCompany}>
                    <div className={style.ImageContainer}>
                        <div className={style.Image}></div>
                    </div>
                    <div className={style.Text}>
                        <h4>Garage Door Master Installation and Repair</h4>
                        <h1>The Garage Door Experts</h1>
                        <p>Our garage door professionals offer a thorough inspection and consultation to help choose the right door. Moreover being a full service construction company, if for instance any repairs to an existing door opening frame or electrical services needed, we can take on the whole project. If you don’t have all the extra time to contact different companies for every aspect of your project, Garage Door Master must be your choice.</p>
                        <Button link="https://facebook.com/pages/category/Garage-Door-Service/Garage-Door-Master-101952908081254" label="Learn more"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;