import React from 'react';
import style from './Testimonials.module.css';
import Carousel from '../Carousel/Carousel';

const Testimonials = () => {
    return (
        <div className={style.Container}>
            <h1>Our reviews</h1>
        <Carousel>
            <div className={style.Testimonial}>
                <h2>"Sam does an amazing job. hired MF for some insulation work and they completed the job on time and extremely professional l."</h2>
                <h3>Peter Oleynik - Facebook</h3>
            </div>
            <div className={style.Testimonial}>
                <h2>"Thank you guys for cleaning our vents!! Great people to work with and will help you understand what you need to do for future maintenance. Very great help!"</h2>
                <h3>Raul Jenny Nasui - Facebook</h3>
            </div>
            <div className={style.Testimonial}>
                <h2>"Thank you guys for your great service! Will recommend to anyone who is looking for good quality work."</h2>
                <h3>Oleg Yakimenko - Facebook</h3>
            </div>
            <div className={style.Testimonial}>
                <h2>"We just had our air conditioning ducts clean and Shaul did a Great job. Thanks so much."</h2>
                <h3>Jimmy Barillas - Facebook</h3>
            </div>
            <div className={style.Testimonial}>
                <h2>"⭐⭐⭐⭐⭐ it was a pleasure to hire this company . From the moment I made an appointment the work started day after. Really clean service and high end work."</h2>
                <h3>Eli Popivker - Facebook</h3>
            </div>
        </Carousel>
    </div>
    );
};

export default Testimonials;