import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from '../components/Home/Home';
import About from '../components/About/About';
import Services from '../components/Services/Services';
import CallToAction from "../components/CallToAction/CallToAction";
import Gallery from '../components/Gallery/Gallery';
import Testimonials from "../components/Testimonials/Testimonials";
import Contact from "../components/Contact/Contact";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Home />
    <About />
    <Services />
    <CallToAction />
    <Gallery />
    <Testimonials />
    <Contact />
  </Layout>
)

export default IndexPage
