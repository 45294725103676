import React from 'react';
import style from './Home.module.css';
import Button from '../Button/Button';

const Home = () => {
    return (
        <div id="home" className={style.Carousel}>
            <div className={style.Slide}>
                <div className={style.Container}>
                    <div className="wrapper">
                        <div className={style.Text}>
                            <p>The Key to Your Home Improvement</p>
                            <h1>Expert Garage Door Services</h1>
                            <Button link="#contact" label="Get a free quote" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;