import React from 'react';
import style from './Services.module.css';
import ServicesCard from '../Cards/ServicesCard';
import { faToolbox, faHammer, faWrench } from '@fortawesome/free-solid-svg-icons';
import img1 from '../../images/services1.jpeg';
import img2 from '../../images/services2.jpeg';
import img3 from '../../images/services3.jpeg';

const Services = () => {
    return (
        <div id="services" className={style.Container}>
            <div className="wrapper">
                <div className={style.Services}>
                    <h1>Our services</h1>
                    <div className={style.CardSection}>
                        <ServicesCard 
                            img={img2}
                            icon={faHammer}
                            title="Garage Door Installation"
                            content="Have a new home or looking to upgrade the current garage you have?  We work with multiple brands and are certified in clean installation of each garage door done."
                        />
                        <ServicesCard
                            img={img1}
                            icon={faWrench}
                            title="Garage Door Repair"
                            content="Our repair technicians are trained to work on the top brands in the industry. If your garage door unit has stopped working completely or is barely moving, call us today!"
                        />
                        <ServicesCard 
                            img={img3}
                            icon={faToolbox}
                            title="Other Services"
                            content="We fix everything related to garage doors and not just what you see here. We repair and install residential and commercial, as well as multiple types of doors and more!"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;