import React from 'react';
import style from './Gallery.module.css';
import galleryimg1 from '../../images/62239352_2369284409804118_5521527960516952064_n.jpg';
import galleryimg2 from '../../images/60290489_2325875604144999_1459548136220393472_n.jpg';
import galleryimg3 from '../../images/60431329_2325875554145004_6978130423482155008_n.jpg';
import galleryimg4 from '../../images/60337811_2325875584145001_6479678736974217216_n.jpg';
import galleryimg5 from '../../images/14591574_1208298889236015_7124013567433730001_n.jpg';
import galleryimg6 from '../../images/51031564_2166497026749525_2355411265431535616_n.jpg';

const Gallery = () => {
    return (
        <div className={style.Container}>
            <img alt='galleryimg' src={galleryimg1} />
            <img alt='galleryimg' src={galleryimg2} />
            <img alt='galleryimg' src={galleryimg3} />
            <img alt='galleryimg' src={galleryimg4} />
            <img alt='galleryimg' src={galleryimg5} />
            <img alt='galleryimg' src={galleryimg6} />
        </div>
    );
};

export default Gallery;