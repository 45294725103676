import { useState, useEffect } from 'react';

function getNextTransition (width, index, translate, direction, setTranslate) {
    const to = width * (index + (direction === 'next' ? 1 : -1));

    if(
        (to - translate < (width / 2) && direction === 'next')
        || (to - translate > -(width / 2) && direction === 'prev')
    ) {
        setTranslate(direction === 'next' ? translate + 1 : translate - 1);
    }

    setTranslate(direction === 'next' ? translate + 2 : translate - 2);
}

export default function useTransition (width, children) {
    const len = children.length;

    const [ index, setIndex ] = useState(1);
    const [ translate, setTranslate ] = useState(width);
    const [ action, setAction ] = useState({ lastAction: '', currentAction: '' });
    const [ items, setItems ] = useState([ children[len - 1], ...children ]);
    const setNextAction = (currentAction) => {
        setAction({
            lastAction: action.currentAction,
            currentAction,
        });
    }

    useEffect(() => {
        if((translate < (index + 1) * width && action.currentAction === 'next') || (translate > (index - 1) * width && action.currentAction === 'prev')) {
            getNextTransition(
                width,
                index,
                translate,
                action.currentAction,
                setTranslate,
            );
        } else if (action.currentAction !== '') {
            setNextAction('');
        }
    }, [translate]);

    useEffect(() => {
        if(action.currentAction === 'next' && index + 1 > len) {
            setItems([ children[len - 1], ...children, children[0]]);
            setTranslate(translate + 1);
        } else if (action.currentAction === 'next') {
            setTranslate(translate + 1);
        } else if (action.currentAction === 'prev') {
            setTranslate(translate - 1);
        } else if (index + 1 > len && action.lastAction === 'next') {
            setItems([ children[len - 1], ...children]);
            setTranslate(width);
            setIndex(1);
        } else if (index - 1 === 0 && action.lastAction === 'prev') {
            setIndex(len);
            setTranslate(len * width);
        } else if (action.lastAction === 'next') {
            setIndex(index + 1);
        } else if (action.lastAction === 'prev') {
            setIndex(index - 1);
        }
    }, [action]);

    return {
        index,
        translate,
        setIndex,
        setTranslate,
        items,
        setAction: setNextAction,
    };
}