import React from 'react';
import style from './AboutCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const CardItem = (props) => {
    return (
        <div className={style.CardItem}>
            <FontAwesomeIcon
                icon={props.icon}
            />
            <p>{props.title}</p>  
        </div>
    );
};

export default CardItem;